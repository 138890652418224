import React, { useState, useEffect } from "react";
import { Table, Select, Divider } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { DatePickerProps } from "antd";
import type { RadioChangeEvent } from "antd";
import { DatePicker, Space, Radio } from "antd";
import dayjs from "dayjs";
import {
  Button,
  Popover,
  Input,
  Form,
  Modal,
  Tooltip,
  message,
  Dropdown,
} from "antd";
import type { MenuProps } from "antd";
import SummaryPopUpTable from "./SummaryPopUpTable";
import { useAppDispatch } from "../../redux/hooks/hooks";
import {
  getSummaryPageData,
  addNotes,
} from "../../redux/reducers/summaryPageSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  street: string;
  building: string;
  number: number;
  companyAddress: string;
  companyName: string;
  gender: string;
}
type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
  hotelnotes?: string;
  rmnote?: string;
};
interface SummaryPageProps {
  summaryData?: any;
  isLoading?: boolean;
  hotelName?: string;
}
const { TextArea } = Input;
const App: React.FC<SummaryPageProps> = (props) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const dispatch = useAppDispatch();
  let token = localStorage.getItem("token");
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } =
    useAuth0();
  const { error, notesData, compSetData } = useSelector(
    (store: any) => store.summaryPage
  );
  const { hotel_id } = useParams();
  const [value, setValue] = useState(1);
  const [popUpShow, setPopUpShow] = useState(false);
  const [hotelNoteOpen, setHotelNoteOpen] = useState(false);
  const [dateRange, setDateRange] = useState(3);
  const [datePickerStatus, setDatePickerStatus] = useState(false);

  let date = new Date();
  var month = date.getMonth();
  var year = date.getFullYear();
  var yesterday = new Date(date.getTime() - 1 * 24 * 60 * 60 * 1000);
  var tomorrow = new Date(date.getTime() + 1 * 24 * 60 * 60 * 1000);
  var last7Day = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
  var next7Day = new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000);
  var lastTwoWeeks = new Date(date.getTime() - 14 * 24 * 60 * 60 * 1000);
  var nextTwoWeeks = new Date(date.getTime() + 14 * 24 * 60 * 60 * 1000);
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  var prevMonthLastDate = new Date(date.getFullYear(), date.getMonth(), 0);
  var prevMonthFirstDate = new Date(
    date.getFullYear() - (date.getMonth() > 0 ? 0 : 1),
    (date.getMonth() - 1 + 12) % 12,
    1
  );
  var nextMonthLastDate = new Date(date.getFullYear(), date.getMonth() + 2, 0);
  var nextMonthFirstDate = new Date(
    date.getFullYear() + (date.getMonth() > 0 ? 0 : 2),
    (date.getMonth() + 1 + 12) % 12,
    1
  );
  var currentYearFirstDate = new Date(year, 0, 1);
  var currentYearLastDate = new Date(year, 11, 31);
  var prevYearFirstDate = new Date(year - 1, 0, 1);
  var prevYearLastDate = new Date(year - 1, 11, 31);
  var nextThreeMonthFirstDate = new Date(year, month + 1, 1);
  var nextThreeMonthLastDate = new Date(year, month + 3, 0);
  const [stayStartDate, setStayStartDate] = useState(
    dayjs(firstDay).format("YYYY-MM-DD")
  );
  const [stayEndDate, setStayEndDate] = useState(
    dayjs(lastDay).format("YYYY-MM-DD")
  );
  const [selectLabel, setSelectLabel] = useState(
    `${dayjs(firstDay).format("MMM DD,YYYY")} - ${dayjs(lastDay).format(
      "MMM DD,YYYY"
    )}`
  );
  const [pickFromLabel, setPickFromLabel] = useState(
    `${dayjs(firstDay).format("MMM DD, YYYY")}`
  );
  console.log("dataaaaaaa", firstDay, lastDay);
  const [open, setOpen] = useState(false);
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date, dateString);
  };

  const generateAccessToken = async () => {
    let accessToken = "";
    if (isAuthenticated) {
      accessToken = await getAccessTokenSilently({
        authorizationParams: {
          domain: domain,
          audience: audience,
          scope: "read:current_user",
        },
      });
      localStorage.setItem("token", accessToken);
    }
  };
  useEffect(() => {
    generateAccessToken();
  }, [user, token]);

  const onRadioChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setDateRange(e.target.value);
    // setValue(e.target.value);
    if (e.target.value == 1) {
      setDatePickerStatus(false);
      setSelectLabel(
        `${dayjs(date).format("MMM DD,YYYY")} - ${dayjs(date).format(
          "MMM DD,YYYY"
        )}`
      );
      setStayStartDate(dayjs(date).format("YYYY-MM-DD"));
      setStayEndDate(dayjs(date).format("YYYY-MM-DD"));
      // setPickFromLabel(`${dayjs(date).format("MMM DD, YYYY")}`);
      let payload = {
        stayStartDate: dayjs(date).format("YYYY-MM-DD"),
        stayEndDate: dayjs(date).format("YYYY-MM-DD"),
        aticaHotelId: hotel_id,
        loggedInUserEmail: user?.email,
        token,
      };
      dispatch(getSummaryPageData(payload));
    }
    if (e.target.value == 2) {
      setDatePickerStatus(false);
      setSelectLabel(
        `${dayjs(yesterday).format("MMM DD,YYYY")} - ${dayjs(yesterday).format(
          "MMM DD,YYYY"
        )}`
      );
      setStayStartDate(dayjs(yesterday).format("YYYY-MM-DD"));
      setStayEndDate(dayjs(yesterday).format("YYYY-MM-DD"));
      // setPickFromLabel(`${dayjs(yesterday).format("MMM DD, YYYY")}`);
      let payload = {
        stayStartDate: dayjs(yesterday).format("YYYY-MM-DD"),
        stayEndDate: dayjs(yesterday).format("YYYY-MM-DD"),
        aticaHotelId: hotel_id,
        loggedInUserEmail: user?.email,
        token,
      };
      dispatch(getSummaryPageData(payload));
    }
    if (e.target.value == 3) {
      setDatePickerStatus(false);
      setSelectLabel(
        `${dayjs(firstDay).format("MMM DD,YYYY")} - ${dayjs(lastDay).format(
          "MMM DD,YYYY"
        )}`
      );
      setStayStartDate(dayjs(firstDay).format("YYYY-MM-DD"));
      setStayEndDate(dayjs(lastDay).format("YYYY-MM-DD"));
      // setPickFromLabel(`${dayjs(firstDay).format("MMM DD, YYYY")}`);
      let payload = {
        stayStartDate: dayjs(firstDay).format("YYYY-MM-DD"),
        stayEndDate: dayjs(lastDay).format("YYYY-MM-DD"),
        aticaHotelId: hotel_id,
        loggedInUserEmail: user?.email,
        token,
      };
      dispatch(getSummaryPageData(payload));
    }
    if (e.target.value == 4) {
      setDatePickerStatus(false);
      setSelectLabel(
        `${dayjs(prevMonthFirstDate).format("MMM DD,YYYY")} - ${dayjs(
          prevMonthLastDate
        ).format("MMM DD,YYYY")}`
      );
      setStayStartDate(dayjs(prevMonthFirstDate).format("YYYY-MM-DD"));
      setStayEndDate(dayjs(prevMonthLastDate).format("YYYY-MM-DD"));
      // setPickFromLabel(`${dayjs(prevMonthFirstDate).format("MMM DD, YYYY")}`);
      let payload = {
        stayStartDate: dayjs(prevMonthFirstDate).format("YYYY-MM-DD"),
        stayEndDate: dayjs(prevMonthLastDate).format("YYYY-MM-DD"),
        aticaHotelId: hotel_id,
        loggedInUserEmail: user?.email,
        token,
      };
      dispatch(getSummaryPageData(payload));
    }
    if (e.target.value == 5) {
      setDatePickerStatus(false);
      setSelectLabel(
        `${dayjs(nextMonthFirstDate).format("MMM DD,YYYY")} - ${dayjs(
          nextMonthLastDate
        ).format("MMM DD,YYYY")}`
      );
      setStayStartDate(dayjs(nextMonthFirstDate).format("YYYY-MM-DD"));
      setStayEndDate(dayjs(nextMonthLastDate).format("YYYY-MM-DD"));
      // setPickFromLabel(`${dayjs(nextMonthFirstDate).format("MMM DD, YYYY")}`);
      let payload = {
        stayStartDate: dayjs(nextMonthFirstDate).format("YYYY-MM-DD"),
        stayEndDate: dayjs(nextMonthLastDate).format("YYYY-MM-DD"),
        aticaHotelId: hotel_id,
        loggedInUserEmail: user?.email,
        token,
      };
      dispatch(getSummaryPageData(payload));
    }
    if (e.target.value == 6) {
      setDatePickerStatus(false);
      setSelectLabel(
        `${dayjs(nextThreeMonthFirstDate).format("MMM DD,YYYY")} - ${dayjs(
          nextThreeMonthLastDate
        ).format("MMM DD,YYYY")}`
      );
      setStayStartDate(dayjs(nextThreeMonthFirstDate).format("YYYY-MM-DD"));
      setStayEndDate(dayjs(nextThreeMonthLastDate).format("YYYY-MM-DD"));
      // setPickFromLabel(`${dayjs(nextThreeMonthFirstDate).format("MMM DD, YYYY")}`);
      let payload = {
        stayStartDate: dayjs(nextThreeMonthFirstDate).format("YYYY-MM-DD"),
        stayEndDate: dayjs(nextThreeMonthLastDate).format("YYYY-MM-DD"),
        aticaHotelId: hotel_id,
        loggedInUserEmail: user?.email,
        token,
      };
      dispatch(getSummaryPageData(payload));
    }
    if (e.target.value == 7) {
      setDatePickerStatus(false);
      setSelectLabel(
        `${dayjs(currentYearFirstDate).format("MMM DD,YYYY")} - ${dayjs(
          currentYearLastDate
        ).format("MMM DD,YYYY")}`
      );
      setStayStartDate(dayjs(currentYearFirstDate).format("YYYY-MM-DD"));
      setStayEndDate(dayjs(currentYearLastDate).format("YYYY-MM-DD"));
      // setPickFromLabel(`${dayjs(currentYearFirstDate).format("MMM DD, YYYY")}`);
      let payload = {
        stayStartDate: dayjs(currentYearFirstDate).format("YYYY-MM-DD"),
        stayEndDate: dayjs(currentYearLastDate).format("YYYY-MM-DD"),
        aticaHotelId: hotel_id,
        loggedInUserEmail: user?.email,
        token,
      };
      dispatch(getSummaryPageData(payload));
    }
    if (e.target.value == 8) {
      setDatePickerStatus(false);
      setSelectLabel(
        `${dayjs(prevYearFirstDate).format("MMM DD,YYYY")} - ${dayjs(
          prevYearLastDate
        ).format("MMM DD,YYYY")}`
      );
      setStayStartDate(dayjs(prevYearFirstDate).format("YYYY-MM-DD"));
      setStayEndDate(dayjs(prevYearLastDate).format("YYYY-MM-DD"));
      // setPickFromLabel(`${dayjs(prevYearFirstDate).format("MMM DD, YYYY")}`);
      let payload = {
        stayStartDate: dayjs(prevYearFirstDate).format("YYYY-MM-DD"),
        stayEndDate: dayjs(prevYearLastDate).format("YYYY-MM-DD"),
        aticaHotelId: hotel_id,
        loggedInUserEmail: user?.email,
        token,
      };
      dispatch(getSummaryPageData(payload));
    }
    if (e.target.value == 9) {
      setDatePickerStatus(true);
    }
  };
  const show = () => {
    setOpen(true);
  };
  const onRMNote = (values: any, data: any) => {
    console.log("Success:", values, data);
    let payload = {
      stayStartDate: data,
      stayEndDate: data,
      rmNotes: values.rmnote,
      // hotelNotes: values.hotelnotes,
      aticaHotelId: hotel_id,
      token,
    };
    dispatch(addNotes(payload)).then((res: any) => {
      if (res?.payload?.data.status == "SUCCESS") {
        message.success(`Changes Saved!`);
        let payload = {
          stayStartDate: stayStartDate,
          stayEndDate: stayEndDate,
          aticaHotelId: hotel_id,
          loggedInUserEmail: user?.email,
          token,
        };
        dispatch(getSummaryPageData(payload));
      } else {
        message.error("Something went wrong..!!");
      }
    });
  };
  const onHotelNote = (values: any, data: any) => {
    console.log("Success:", values);
    let payload = {
      stayStartDate: data,
      stayEndDate: data,
      // rmNotes: values.rmnote,
      hotelNotes: values.hotelnotes,
      aticaHotelId: hotel_id,
      token,
    };
    dispatch(addNotes(payload)).then((res: any) => {
      if (res?.payload?.data.status == "SUCCESS") {
        message.success(`Changes Saved!`);
        let payload = {
          stayStartDate: stayStartDate,
          stayEndDate: stayEndDate,
          aticaHotelId: hotel_id,
          loggedInUserEmail: user?.email,
          token,
        };
        dispatch(getSummaryPageData(payload));
      } else {
        message.error("Something went wrong..!!");
      }
    });
  };

  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const [rmNoteValue, setRmNoteValue] = useState("");
  const RMContent = (props: any) => {
    return (
      <div>
        <Form
          onFinish={(e) => onRMNote(e, props.data.date)}
          initialValues={{
            rmnote: props.data?.rmNotes,
          }}
        >
          <Form.Item<FieldType>
            name="rmnote"
            rules={[{ required: true, message: "Please input your text!" }]}
          >
            {/* <Input /> */}
            <TextArea rows={2} />
          </Form.Item>
          <div className="flex flex-wrap items-center justify-end mt-2">
            {/* <button
            className="text-[#004A98]"
            onClick={() => setHotelNoteOpen(false)}
          >
            Cancel
          </button> */}
            <button
              type="submit"
              className={`ml-2 px-4 py-1 bg-[#004A98] rounded-md text-white text-sm font-normal leading-tight tracking-tight`}
            >
              Save
            </button>
          </div>
        </Form>
      </div>
    );
  };
  const HotelContent = (props: any) => {
    console.log("HotelAddContent", props.data);
    return (
      <div>
        <Form
          onFinish={(e) => onHotelNote(e, props?.data?.date)}
          initialValues={{
            hotelnotes: props?.data?.hotelNotes,
          }}
        >
          <Form.Item<FieldType>
            name="hotelnotes"
            rules={[{ required: true, message: "Please input your text!" }]}
          >
            {/* <Input /> */}
            <TextArea rows={2} />
          </Form.Item>
          <div className="flex flex-wrap items-center justify-end mt-2">
            {/* <button
            className="text-[#004A98]"
            onClick={() => setHotelNoteOpen(false)}
          >
            Cancel
          </button> */}
            <button
              type="submit"
              className=" ml-2 px-4 py-1 bg-[#004A98] rounded-md text-white text-sm font-normal leading-tight tracking-tight"
            >
              Save
            </button>
          </div>
        </Form>
      </div>
    );
  };

  const items: MenuProps["items"] = [
    {
      label: "Atica Forecast",
      key: "0",
    },
    {
      label: "RMS Forecast",
      key: "1",
    },
  ];
  const highlightRow = (record: any) => {
    return dayjs(record.date).format("ddd") == "Fri" ||
      dayjs(record.date).format("ddd") == "Sat"
      ? "highlighted-row"
      : "";
  };
  const columns: ColumnsType<DataType> = [
    {
      title: "Date Range",
      align: "center",
      children: [
        {
          title: (
            <Select
              style={{
                width: 250,
              }}
              dropdownMatchSelectWidth={false}
              // onChange={onSelect}
              // onClick={() => setOpen(!open)}
              // onBlur={() => setOpen(false)}
              suffixIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M13.3537 6.35375L8.35366 11.3538C8.30722 11.4002 8.25207 11.4371 8.19138 11.4623C8.13068 11.4874 8.06561 11.5004 7.99991 11.5004C7.9342 11.5004 7.86913 11.4874 7.80844 11.4623C7.74774 11.4371 7.69259 11.4002 7.64616 11.3538L2.64616 6.35375C2.57615 6.28382 2.52847 6.1947 2.50914 6.09765C2.48982 6.00061 2.49972 5.90002 2.53759 5.8086C2.57547 5.71719 2.63962 5.63908 2.72191 5.58414C2.80421 5.5292 2.90096 5.49992 2.99991 5.5H12.9999C13.0989 5.49992 13.1956 5.5292 13.2779 5.58414C13.3602 5.63908 13.4243 5.71719 13.4622 5.8086C13.5001 5.90002 13.51 6.00061 13.4907 6.09765C13.4713 6.1947 13.4237 6.28382 13.3537 6.35375Z"
                    fill="#545454"
                  />
                </svg>
              }
              // placeholder="custom dropdown render"
              value={selectLabel}
              dropdownRender={(menu) => (
                <>
                  <div
                    className="w-full items-center mx-5 my-3"
                    style={{ width: 400 }}
                    // style={{
                    //   padding: "0 8px 4px",
                    // }}
                  >
                    <Radio.Group onChange={onRadioChange} value={dateRange}>
                      <div className="grid grid-cols-2 gap-4 w-full">
                        <Radio value={1}>Today</Radio>
                        <Radio value={2}>Yesterday</Radio>
                        <Radio value={3}>This Month</Radio>
                        <Radio value={4}>Last Month</Radio>
                        <Radio value={5}>Next Month</Radio>
                        <Radio value={6}>Next 3 Months</Radio>
                        <Radio value={7}>This Year</Radio>
                        <Radio value={8}>Last Year</Radio>
                        <Radio value={9}>Custom Range</Radio>
                      </div>
                    </Radio.Group>
                    {datePickerStatus && (
                      <div className="w-full flex flex-wrap items-center justify-between mt-2">
                        <DatePicker
                          onChange={onChange}
                          placeholder="Start Date"
                        />
                        <DatePicker
                          onChange={onChange}
                          placeholder="End Date"
                        />
                      </div>
                    )}
                  </div>
                  {/* {menu} */}
                  <Divider
                    style={{
                      margin: "8px 0",
                    }}
                  />
                  <div className="flex flex-wrap items-center justify-between mx-4 mb-1">
                    <div className=" flex items-center">
                      <p className="font-roboto text-sm font-semibold text-ellipsis overflow-hidden tracking-[0.5px]">
                        {selectLabel}
                      </p>
                    </div>
                    <div className="flex items-center justify-around">
                      {/* <button className="text-[#004A98]">Cancel</button> */}
                      <button className=" px-4 py-1 bg-neutral-400 rounded-md text-white text-sm font-normal leading-tight tracking-tight">
                        Apply
                      </button>
                    </div>
                  </div>
                </>
              )}
              // options={newArray.map((item: any) => ({
              //   label: item.label,
              //   key: item.key,
              // }))}
            />
          ),
          // dataIndex: "date",
          // key: "date",
          width: 300,
          align: "center",
          fixed: "left",
          render: (records) => (
            <div className="flex flex-wrap items-center">
              <div className="ml-4 w-14">
                {records?.rmNotes || records?.hotelNotes ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                  >
                    <circle cx="5" cy="5" r="5" fill="#336EAD" />
                  </svg>
                ) : (
                  ""
                )}
              </div>

              <div>
                <p className="font-roboto text-sm tracking-[0.5px]">
                  {/* Aug 31, 2023 | Thur */}
                  {dayjs(records?.date).format("MMM DD, YYYY | ddd")}
                </p>
              </div>
            </div>
          ),
          // sorter: (a, b) => a.age - b.age,
        },
      ],
    },
    {
      width: 6,
      align: "center",
      dataIndex: "key",
      key: "key",
      render: () => <p className="bg-white border-none">&nbsp;</p>,
    },
    {
      title: "On The Books",
      align: "center",
      fixed: "left",
      render: () => {
        return <p onClick={() => setOpen(true)}></p>;
      },
      children: [
        {
          title: "Occupancy",
          dataIndex: "occupancy",
          key: "occupancy",
          width: 130,
          align: "center",
          fixed: "left",
          render: (record) => (
            <div className="flex items-center justify-center">
              {/* <Tooltip title="Click for more details" placement="bottom"> */}
              <p
                // onClick={() => setOpen(true)}
                className="font-roboto text-sm text-center flex tracking-[0.5px]"
              >
                {record}%&nbsp;
                {record <= 30 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM3.57797 10C3.57797 13.5468 6.45321 16.422 10 16.422C13.5468 16.422 16.422 13.5468 16.422 10C16.422 6.45321 13.5468 3.57797 10 3.57797C6.45321 3.57797 3.57797 6.45321 3.57797 10Z"
                      fill="#CDD8E3"
                    />
                    <path
                      d="M10 1.78898C10 0.800956 10.8073 -0.0162414 11.7795 0.159587C13.4703 0.465356 15.0644 1.20375 16.4 2.31625C17.7357 3.42875 18.7501 4.86305 19.3565 6.47068C19.7052 7.39512 19.0474 8.33682 18.0757 8.51545C17.104 8.69409 16.1924 8.03195 15.7534 7.14679C15.3592 6.35198 14.8017 5.64155 14.1101 5.06547C13.4185 4.4894 12.619 4.06952 11.766 3.82555C10.816 3.55384 10 2.77701 10 1.78898Z"
                      fill="#9C0000"
                    />
                  </svg>
                ) : (
                  ""
                )}
                {record > 30 && record <= 50 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM3.57797 10C3.57797 13.5468 6.45321 16.422 10 16.422C13.5468 16.422 16.422 13.5468 16.422 10C16.422 6.45321 13.5468 3.57797 10 3.57797C6.45321 3.57797 3.57797 6.45321 3.57797 10Z"
                      fill="#CDD8E3"
                    />
                    <path
                      d="M10 1.78898C10 0.800956 10.8072 -0.0162335 11.7795 0.159592C13.2762 0.43026 14.6996 1.04035 15.9362 1.95255C17.6555 3.22082 18.9235 5.00642 19.5543 7.0477C20.185 9.08898 20.1454 11.2786 19.4413 13.2958C18.9348 14.7466 18.1038 16.0533 17.0207 17.1211C16.3171 17.8148 15.1896 17.5955 14.6322 16.7797C14.0748 15.9639 14.3103 14.8621 14.9414 14.1019C15.4278 13.5159 15.8091 12.8445 16.0632 12.1165C16.5154 10.8211 16.5409 9.41494 16.1358 8.10402C15.7307 6.79311 14.9164 5.64639 13.8122 4.8319C13.1918 4.37421 12.4981 4.03495 11.766 3.82555C10.816 3.55385 10 2.77701 10 1.78898Z"
                      fill="#ED8D4C"
                    />
                  </svg>
                ) : (
                  ""
                )}
                {record > 50 && record <= 80 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM3.57797 10C3.57797 13.5468 6.45321 16.422 10 16.422C13.5468 16.422 16.422 13.5468 16.422 10C16.422 6.45321 13.5468 3.57797 10 3.57797C6.45321 3.57797 3.57797 6.45321 3.57797 10Z"
                      fill="#CDD8E3"
                    />
                    <path
                      d="M10 1.78898C10 0.800956 10.8072 -0.0162256 11.7795 0.159597C13.0449 0.388427 14.2599 0.860217 15.3553 1.55484C16.9569 2.57043 18.2366 4.02049 19.0453 5.73585C19.8539 7.45122 20.1582 9.36117 19.9225 11.2429C19.6868 13.1246 18.9208 14.9005 17.7141 16.3634C16.5073 17.8263 14.9094 18.9159 13.1069 19.5051C11.3043 20.0943 9.37134 20.1588 7.53351 19.691C5.69568 19.2233 4.02874 18.2426 2.72716 16.8634C1.83693 15.92 1.14272 14.8169 0.677467 13.6181C0.319986 12.697 0.968775 11.7491 1.93878 11.5612C2.90878 11.3734 3.82661 12.0268 4.27396 12.9078C4.55082 13.4529 4.90549 13.9585 5.32936 14.4077C6.16524 15.2934 7.23575 15.9232 8.41601 16.2236C9.59627 16.524 10.8376 16.4826 11.9952 16.1042C13.1529 15.7258 14.179 15.0261 14.954 14.0866C15.729 13.1471 16.2209 12.0066 16.3722 10.7982C16.5236 9.58974 16.3282 8.36316 15.8089 7.26155C15.2896 6.15994 14.4677 5.22871 13.4392 4.57649C12.9176 4.24576 12.3538 3.99369 11.766 3.82554C10.816 3.55385 10 2.77701 10 1.78898Z"
                      fill="#69B073"
                    />
                  </svg>
                ) : (
                  ""
                )}
                {record > 80 && record <= 100 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM3.57797 10C3.57797 13.5468 6.45321 16.422 10 16.422C13.5468 16.422 16.422 13.5468 16.422 10C16.422 6.45321 13.5468 3.57797 10 3.57797C6.45321 3.57797 3.57797 6.45321 3.57797 10Z"
                      fill="#CDD8E3"
                    />
                    <path
                      d="M10 1.78898C10 0.800956 10.8072 -0.0162436 11.7795 0.159584C13.5002 0.470769 15.1203 1.22997 16.4696 2.37472C18.2766 3.90786 19.4804 6.03291 19.8664 8.37103C20.2525 10.7091 19.7956 13.1084 18.5773 15.141C17.359 17.1736 15.4584 18.7075 13.2144 19.4693C10.9704 20.231 8.52882 20.1711 6.32488 19.3002C4.12095 18.4293 2.29793 16.804 1.18081 14.714C0.0636978 12.6241 -0.274904 10.2053 0.225367 7.88895C0.598907 6.15937 1.42201 4.57082 2.59771 3.27642C3.26201 2.54505 4.39993 2.70195 5.0014 3.4858C5.60288 4.26965 5.42838 5.38272 4.84016 6.17657C4.30044 6.90497 3.91693 7.74493 3.7227 8.64427C3.40142 10.1319 3.61888 11.6852 4.33629 13.0274C5.0537 14.3695 6.22445 15.4133 7.63983 15.9726C9.0552 16.5319 10.6232 16.5704 12.0643 16.0812C13.5054 15.592 14.726 14.6069 15.5084 13.3016C16.2908 11.9962 16.5842 10.4554 16.3363 8.95387C16.0883 7.45232 15.3152 6.08761 14.1548 5.10302C13.4532 4.50777 12.6376 4.07485 11.766 3.82555C10.816 3.55384 10 2.77701 10 1.78898Z"
                      fill="#367D40"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </p>
              {/* </Tooltip> */}
            </div>
          ),
        },
        {
          title: "ADR",
          dataIndex: "adr",
          key: "adr",
          align: "center",
          fixed: "left",
          width: 100,
          render: (record: any) => {
            const formattedPrice = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(record);
            const priceWithoutDecimal = formattedPrice.replace(/\.00$/, "");
            return (
              // <Tooltip title="Click for more details" placement="bottom">
              <p
                // onClick={() => setOpen(true)}
                className="font-roboto text-sm tracking-[0.5px] "
              >
                {priceWithoutDecimal}
              </p>
              // </Tooltip>
            );
          },
        },
        {
          title: "Revenue",
          dataIndex: "revenue",
          key: "revenue",
          align: "center",
          width: 100,
          fixed: "left",
          render: (record) => {
            const formattedPrice = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(Math.round(record));
            const priceWithoutDecimal = formattedPrice.replace(/\.00$/, "");
            return (
              // <Tooltip title="Click for more details" placement="bottom">
              <p
                // onClick={() => setOpen(true)}
                className="font-roboto text-sm tracking-[0.5px]"
              >
                {priceWithoutDecimal}
              </p>
              // </Tooltip>
            );
          },
        },
        {
          title: "RevPAR ",
          dataIndex: "revpar",
          key: "revpar",
          align: "center",
          width: 100,
          fixed: "left",
          render: (record) => {
            const formattedPrice = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(record);
            const priceWithoutDecimal = formattedPrice.replace(/\.00$/, "");
            return (
              // <Tooltip title="Click for more details" placement="bottom">
              <p
                // onClick={() => setOpen(true)}
                className="font-roboto text-sm tracking-[0.5px]"
              >
                {priceWithoutDecimal}
              </p>
              // </Tooltip>
            );
          },
        },
        {
          title: "OOO/Total Rooms",
          // dataIndex: "oooTotalRooms",
          // key: "oooTotalRooms",
          align: "center",
          width: 100,
          fixed: "left",
          render: (records: any) => (
            // <Tooltip title="Click for more details" placement="bottom">
            <p
              onClick={() => setOpen(true)}
              className="font-roboto text-sm tracking-[0.5px]"
            >
              {records.oooTotalRooms}
              {records.oooTotalRooms && records.totalRooms ? "/" : " "}
              {records.totalRooms}
            </p>
            // </Tooltip>
          ),
        },
        {
          title: "Available",
          dataIndex: "available",
          key: "available",
          align: "center",
          width: 100,
          fixed: "left",
          render: (record) => (
            // <Tooltip title="Click for more details" placement="bottom">
            <p
              // onClick={() => setOpen(true)}
              className="font-roboto text-sm tracking-[0.5px]"
            >
              {record}
            </p>
            // </Tooltip>
          ),
        },
        {
          title: "Occupied",
          dataIndex: "occupied",
          key: "occupied",
          align: "center",
          width: 100,
          fixed: "left",
          render: (record) => (
            // <Tooltip title="Click for more details" placement="bottom">
            <p
              // onClick={() => setOpen(true)}
              className="font-roboto text-sm tracking-[0.5px]"
            >
              {record}
            </p>
            // </Tooltip>
          ),
        },
      ],
    },
    {
      width: 6,
      render: () => <p className="bg-white border-none">&nbsp;</p>,
    },
    // {
    //   title: "Gender",
    //   dataIndex: "gender",
    //   key: "gender",
    //   width: 80,
    //   // fixed: "right",
    // },
    {
      title: "Variance",
      align: "center",
      children: [
        {
          title: "STLY",
          dataIndex: "variance",
          key: "variance",
          width: 150,
          align: "center",
          render: (record) => {
            const formattedPrice = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(record);
            const priceWithoutDecimal = formattedPrice.replace(/\.00$/, "");
            return (
              <p className="font-roboto text-sm tracking-[0.5px]">
                {priceWithoutDecimal}
              </p>
            );
          },
        },
      ],
    },
    {
      width: 6,
      render: () => <p className="bg-white border-none">&nbsp;</p>,
    },
    {
      title: "Group",
      align: "center",
      children: [
        {
          title: "OTB/Block",
          dataIndex: "groupOTBBlocks",
          key: "groupOTBBlocks",
          width: 150,
          align: "center",
          render: (record) => (
            <p className="font-roboto text-sm tracking-[0.5px]">{record}</p>
          ),
        },
      ],
    },
    {
      width: 6,
      render: () => <p className="bg-white border-none">&nbsp;</p>,
    },
    {
      title: "Pickup from yesterday",
      // title: () => (
      //   <p>
      //     Pickup From{" "}
      //     <span className="text-black text-sm tracking-[0.5px] font-roboto font-normal">
      //       {pickFromLabel}
      //     </span>
      //   </p>
      // ),
      align: "center",
      children: [
        {
          title: "Rooms",
          dataIndex: "pickupRooms",
          key: "pickupRooms",
          width: 100,
          align: "center",
          render: (record) => (
            <p className="font-roboto text-sm tracking-[0.5px]">{record}</p>
          ),
        },
        {
          title: "ADR",
          dataIndex: "pickupAdr",
          key: "pickupAdr",
          align: "center",
          width: 100,
          render: (record) => {
            const formattedPrice = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(record);
            const priceWithoutDecimal = formattedPrice.replace(/\.00$/, "");
            return (
              <p className="font-roboto text-sm tracking-[0.5px]">
                {priceWithoutDecimal}
              </p>
            );
          },
        },
      ],
    },
    {
      width: 6,
      render: () => <p className="bg-white border-none">&nbsp;</p>,
    },
    {
      title: "Pricing and Forecast",
      align: "center",
      children: [
        {
          title: "Atica Forecast",
          // title: (
          //   <Dropdown menu={{ items }} trigger={["click"]}>
          //     <a onClick={(e) => e.preventDefault()}>
          //       <Space>
          //         <p className="text-black text-sm tracking-[0.5px] font-roboto">
          //           Atica Forecast
          //         </p>
          //         <svg
          //           xmlns="http://www.w3.org/2000/svg"
          //           width="16"
          //           height="16"
          //           viewBox="0 0 16 16"
          //           fill="none"
          //         >
          //           <path
          //             d="M13.3541 6.35375L8.35414 11.3538C8.30771 11.4002 8.25256 11.4371 8.19186 11.4623C8.13117 11.4874 8.0661 11.5004 8.00039 11.5004C7.93469 11.5004 7.86962 11.4874 7.80892 11.4623C7.74822 11.4371 7.69308 11.4002 7.64664 11.3538L2.64664 6.35375C2.57664 6.28382 2.52895 6.1947 2.50963 6.09765C2.4903 6.00061 2.50021 5.90002 2.53808 5.8086C2.57596 5.71719 2.64011 5.63908 2.7224 5.58414C2.8047 5.5292 2.90145 5.49992 3.00039 5.5H13.0004C13.0993 5.49992 13.1961 5.5292 13.2784 5.58414C13.3607 5.63908 13.4248 5.71719 13.4627 5.8086C13.5006 5.90002 13.5105 6.00061 13.4912 6.09765C13.4718 6.1947 13.4241 6.28382 13.3541 6.35375Z"
          //             fill="#545454"
          //           />
          //         </svg>
          //       </Space>
          //     </a>
          //   </Dropdown>
          // ),
          dataIndex: "forecast",
          key: "forecast",
          width: 150,
          align: "center",
          render: (record) => (
            <p
              className={`font-roboto text-sm ${
                record <= 30 ? "text-red-800" : ""
              } ${record > 30 && record <= 50 ? "text-[#E8701F]" : ""}  ${
                record > 50 && record <= 80 ? "text-[#439C50]" : ""
              } ${record > 80 ? "text-[#285E30]" : ""} tracking-[0.5px]`}
            >
              {record}%
            </p>
          ),
        },
        {
          title: "Current BAR",
          dataIndex: "currentBar",
          key: "currentBar",
          align: "center",
          width: 100,
          render: (record) => {
            const formattedPrice = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(record);
            const priceWithoutDecimal = formattedPrice.replace(/\.00$/, "");
            return (
              <p className="font-roboto text-sm tracking-[0.5px]">
                {priceWithoutDecimal}
              </p>
            );
          },
        },
      ],
    },
    {
      width: 6,
      render: () => <p className="bg-white border-none">&nbsp;</p>,
    },
    {
      title: "Competitive Analysis",
      align: "center",
      children: [
        {
          title: () => (
            <Tooltip title={props?.hotelName} placement="bottom">
              <p className="text-black text-sm truncate tracking-[0.5px] font-roboto">
                {props?.hotelName}
              </p>
            </Tooltip>
          ),
          dataIndex: "adr",
          key: "adr",
          width: 150,
          align: "center",
          render: (record) => {
            const formattedPrice = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(record);
            const priceWithoutDecimal = formattedPrice.replace(/\.00$/, "");
            return (
              <p className="font-roboto text-sm tracking-[0.5px]">
                {priceWithoutDecimal}
              </p>
            );
          },
        },
        {
          title: "Avg. Compset",
          dataIndex: "avgCompset",
          key: "avgCompset",
          align: "center",
          width: 100,
          render: (record) => {
            const formattedPrice = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(record);
            const priceWithoutDecimal = formattedPrice.replace(/\.00$/, "");
            return (
              <p className="font-roboto text-sm tracking-[0.5px]">
                {priceWithoutDecimal}
              </p>
            );
          },
        },
        {
          title: "ARI",
          dataIndex: "ari",
          key: "ari",
          align: "center",
          width: 100,
          render: (record) => (
            <p className="font-roboto text-sm tracking-[0.5px]">{record}</p>
          ),
        },
        {
          title: "Price Rank",
          dataIndex: "priceRank",
          key: "priceRank",
          align: "center",
          width: 100,
          render: (record) => (
            <p className="font-roboto text-sm tracking-[0.5px]">{record}</p>
          ),
        },
        // {
        //   title: () => (
        //     <Tooltip title="nbabdada" placement="bottom">
        //       <p className="font-roboto truncate text-sm tracking-[0.5px]">
        //         Test fdbdhreherhrehha
        //       </p>
        //     </Tooltip>
        //   ),
        //   // ellipsis: true,
        //   dataIndex: "companyName",
        //   key: "companyName",
        //   align: "center",
        //   width: 100,
        //   render: () => (
        //     <p className="font-roboto text-sm tracking-[0.5px]">23</p>
        //   ),
        // },
        // compSetData &&
        //   compSetData?.map((item: any) => ({
        //     // title: item?.compsetName,
        //     title: () => (
        //       <Tooltip title={item?.compsetName} placement="bottom">
        //         <p className="font-roboto truncate text-sm tracking-[0.5px]">
        //           {item?.compsetName}
        //         </p>
        //       </Tooltip>
        //     ),
        //     dataIndex: "companyName",
        //     key: "companyName",
        //     align: "center",
        //     width: 100,
        //     render: () => (
        //       <p className="font-roboto text-sm tracking-[0.5px]">24</p>
        //     ),
        //   })),
        // {
        //   title: "Competitor 2",
        //   dataIndex: "companyName",
        //   key: "companyName",
        //   align: "center",
        //   width: 100,
        //   render: () => (
        //     <p className="font-roboto text-sm tracking-[0.5px]">24</p>
        //   ),
        // },
        // {
        //   title: "Competitor 3",
        //   dataIndex: "companyName",
        //   key: "companyName",
        //   align: "center",
        //   width: 100,
        //   render: () => (
        //     <p className="font-roboto text-sm tracking-[0.5px]">25</p>
        //   ),
        // },
        // {
        //   title: "Competitor 4",
        //   dataIndex: "companyName",
        //   key: "companyName",
        //   align: "center",
        //   width: 100,
        //   render: () => (
        //     <p className="font-roboto text-sm tracking-[0.5px]">26</p>
        //   ),
        // },
        // {
        //   title: "Competitor 5",
        //   dataIndex: "companyName",
        //   key: "companyName",
        //   align: "center",
        //   width: 100,
        //   render: () => (
        //     <p className="font-roboto text-sm tracking-[0.5px]">27</p>
        //   ),
        // },
      ],
    },
    {
      width: 6,
      render: () => <p className="bg-white border-none">&nbsp;</p>,
    },
    {
      title: "Notes",
      align: "center",
      children: [
        {
          title: "RM Notes",
          // dataIndex: "rmNotes",
          key: "rmNotes",
          width: 200,
          align: "center",
          render: (records) => {
            return records?.rmNotes && !props.isLoading ? (
              <div className="flex items-center justify-between">
                <Tooltip
                  title={records.rmNotes ? records.rmNotes : ""}
                  placement="bottom"
                >
                  <p className="flex font-roboto text-sm tracking-[0.5px] overflow-hidden truncate text-ellipsis">
                    {records?.rmNotes} &nbsp;
                  </p>
                </Tooltip>
                <div className=" flex items-center justify-center cursor-pointer">
                  <Popover
                    placement="bottom"
                    content={<RMContent data={records} />}
                    title={dayjs(records.date).format("MMM DD, YYYY | ddd")}
                    trigger="click"
                    // open={popUpShow}
                    // onOpenChange={handleOpenChange}
                  >
                    {/* <Button> */}
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M14.3838 4.40881L11.5913 1.61569C11.4752 1.49956 11.3374 1.40744 11.1857 1.34459C11.034 1.28174 10.8714 1.24939 10.7072 1.24939C10.543 1.24939 10.3804 1.28174 10.2287 1.34459C10.077 1.40744 9.93921 1.49956 9.82313 1.61569L2.11626 9.32318C1.99976 9.43892 1.90739 9.57664 1.84452 9.72834C1.78165 9.88004 1.74953 10.0427 1.75001 10.2069V13.0001C1.75001 13.3316 1.8817 13.6495 2.11612 13.8839C2.35054 14.1184 2.66848 14.2501 3.00001 14.2501H5.79313C5.95734 14.2505 6.12001 14.2184 6.27171 14.1555C6.42341 14.0926 6.56112 14.0003 6.67688 13.8838L14.3838 6.17631C14.6181 5.9419 14.7497 5.62402 14.7497 5.29256C14.7497 4.9611 14.6181 4.64322 14.3838 4.40881ZM5.68751 12.7501H3.25001V10.3126L8.50001 5.06256L10.9375 7.50006L5.68751 12.7501ZM12 6.43756L9.56251 4.00006L10.7088 2.85381L13.1463 5.29131L12 6.43756Z"
                          fill="#004A98"
                        />
                      </svg>
                    </p>

                    {/* </Button> */}
                  </Popover>
                </div>
              </div>
            ) : (
              <div className=" flex items-center justify-center cursor-pointer">
                <Popover
                  placement="bottom"
                  content={<RMContent data={records} />}
                  title={dayjs(records.date).format("MMM DD, YYYY | ddd")}
                  trigger="click"
                  // open={hotelNoteOpen}
                  // onOpenChange={handleOpenChange}
                >
                  {/* <Button> */}
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M14.25 8C14.25 8.19891 14.171 8.38968 14.0303 8.53033C13.8897 8.67098 13.6989 8.75 13.5 8.75H8.75V13.5C8.75 13.6989 8.67098 13.8897 8.53033 14.0303C8.38968 14.171 8.19891 14.25 8 14.25C7.80109 14.25 7.61032 14.171 7.46967 14.0303C7.32902 13.8897 7.25 13.6989 7.25 13.5V8.75H2.5C2.30109 8.75 2.11032 8.67098 1.96967 8.53033C1.82902 8.38968 1.75 8.19891 1.75 8C1.75 7.80109 1.82902 7.61032 1.96967 7.46967C2.11032 7.32902 2.30109 7.25 2.5 7.25H7.25V2.5C7.25 2.30109 7.32902 2.11032 7.46967 1.96967C7.61032 1.82902 7.80109 1.75 8 1.75C8.19891 1.75 8.38968 1.82902 8.53033 1.96967C8.67098 2.11032 8.75 2.30109 8.75 2.5V7.25H13.5C13.6989 7.25 13.8897 7.32902 14.0303 7.46967C14.171 7.61032 14.25 7.80109 14.25 8Z"
                        fill="#004A98"
                      />
                    </svg>
                  </p>

                  {/* </Button> */}
                </Popover>
              </div>
            );
          },
        },
        {
          title: "Hotel Notes",
          // dataIndex: "hotelNotes",
          key: "hotelNotes",
          width: 200,
          align: "center",
          render: (records) => {
            return records.hotelNotes && !props.isLoading ? (
              <div className=" flex items-center justify-between">
                <Tooltip
                  title={records.hotelNotes ? records.hotelNotes : ""}
                  placement="bottom"
                >
                  <p className="flex font-roboto text-sm tracking-[0.5px] overflow-hidden truncate text-ellipsis">
                    {records.hotelNotes} &nbsp;
                  </p>
                </Tooltip>

                <div className=" flex items-center justify-center cursor-pointer">
                  <Popover
                    placement="bottom"
                    content={<HotelContent data={records} />}
                    title={dayjs(records.date).format("MMM DD, YYYY | ddd")}
                    trigger="click"
                    // open={popUpShow}
                    // onOpenChange={handleOpenChange}
                  >
                    {/* <Button> */}
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M14.3838 4.40881L11.5913 1.61569C11.4752 1.49956 11.3374 1.40744 11.1857 1.34459C11.034 1.28174 10.8714 1.24939 10.7072 1.24939C10.543 1.24939 10.3804 1.28174 10.2287 1.34459C10.077 1.40744 9.93921 1.49956 9.82313 1.61569L2.11626 9.32318C1.99976 9.43892 1.90739 9.57664 1.84452 9.72834C1.78165 9.88004 1.74953 10.0427 1.75001 10.2069V13.0001C1.75001 13.3316 1.8817 13.6495 2.11612 13.8839C2.35054 14.1184 2.66848 14.2501 3.00001 14.2501H5.79313C5.95734 14.2505 6.12001 14.2184 6.27171 14.1555C6.42341 14.0926 6.56112 14.0003 6.67688 13.8838L14.3838 6.17631C14.6181 5.9419 14.7497 5.62402 14.7497 5.29256C14.7497 4.9611 14.6181 4.64322 14.3838 4.40881ZM5.68751 12.7501H3.25001V10.3126L8.50001 5.06256L10.9375 7.50006L5.68751 12.7501ZM12 6.43756L9.56251 4.00006L10.7088 2.85381L13.1463 5.29131L12 6.43756Z"
                          fill="#004A98"
                        />
                      </svg>
                    </p>

                    {/* </Button> */}
                  </Popover>
                </div>
              </div>
            ) : (
              <div className=" flex items-center justify-center cursor-pointer">
                <Popover
                  placement="bottom"
                  content={<HotelContent data={records} />}
                  title={dayjs(records.date).format("MMM DD, YYYY | ddd")}
                  trigger="click"
                  // open={hotelNoteOpen}
                  // onOpenChange={handleOpenChange}
                >
                  {/* <Button> */}
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M14.25 8C14.25 8.19891 14.171 8.38968 14.0303 8.53033C13.8897 8.67098 13.6989 8.75 13.5 8.75H8.75V13.5C8.75 13.6989 8.67098 13.8897 8.53033 14.0303C8.38968 14.171 8.19891 14.25 8 14.25C7.80109 14.25 7.61032 14.171 7.46967 14.0303C7.32902 13.8897 7.25 13.6989 7.25 13.5V8.75H2.5C2.30109 8.75 2.11032 8.67098 1.96967 8.53033C1.82902 8.38968 1.75 8.19891 1.75 8C1.75 7.80109 1.82902 7.61032 1.96967 7.46967C2.11032 7.32902 2.30109 7.25 2.5 7.25H7.25V2.5C7.25 2.30109 7.32902 2.11032 7.46967 1.96967C7.61032 1.82902 7.80109 1.75 8 1.75C8.19891 1.75 8.38968 1.82902 8.53033 1.96967C8.67098 2.11032 8.75 2.30109 8.75 2.5V7.25H13.5C13.6989 7.25 13.8897 7.32902 14.0303 7.46967C14.171 7.61032 14.25 7.80109 14.25 8Z"
                        fill="#004A98"
                      />
                    </svg>
                  </p>

                  {/* </Button> */}
                </Popover>
              </div>
            );
          },
        },
        {
          title: "Events",
          dataIndex: "event",
          key: "event",
          width: 200,
          align: "center",
          render: (record) => {
            return record ? (
              <>
                <Tooltip title={record ? record : ""} placement="bottom">
                  <p className="flex font-roboto text-sm tracking-[0.5px] truncate">
                    {record}
                  </p>
                </Tooltip>
              </>
            ) : (
              <div className="flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                >
                  <path
                    d="M14.75 8C14.75 8.19891 14.671 8.38968 14.5303 8.53033C14.3897 8.67098 14.1989 8.75 14 8.75H3C2.80109 8.75 2.61032 8.67098 2.46967 8.53033C2.32902 8.38968 2.25 8.19891 2.25 8C2.25 7.80109 2.32902 7.61032 2.46967 7.46967C2.61032 7.32902 2.80109 7.25 3 7.25H14C14.1989 7.25 14.3897 7.32902 14.5303 7.46967C14.671 7.61032 14.75 7.80109 14.75 8Z"
                    fill="#BABABA"
                  />
                </svg>
              </div>
            );
          },
        },
      ],
    },
  ];

  const data: DataType[] = [];
  for (let i = 0; i < 100; i++) {
    data.push({
      key: i,
      name: "John Brown",
      age: i + 1,
      street: "Lake Park",
      building: "C",
      number: 2035,
      companyAddress: "Lake Street 42",
      companyName: "SoftLake Co",
      gender: "M",
    });
  }
  const scrollConfig = {
    y: "max-content",
    x: true,
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={props?.summaryData?.dailySummaryDetails}
        bordered
        size="small"
        // scroll={{ y: 600 }}
        // pagination={{
        //   position: ["bottomCenter"],
        // }}
        // pageSize={30}
        rowClassName={highlightRow}
        pagination={false}
        scroll={{
          scrollToFirstRowOnChange: false,
          y: "65vh",
        }}
        loading={props?.isLoading}
      />
      <Modal
        title="On the Books | Sep 05, 2023 - Tue"
        centered
        open={open}
        footer={null}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1500}
      >
        <SummaryPopUpTable />
      </Modal>
    </>
  );
};

export default App;
